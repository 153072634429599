import React from 'react'
import './Module.css'
import ModuleLessonCard from './Cards/ModuleLessonCard'
import { useState } from 'react';
import { useEffect } from 'react';
import { collection, doc, getDoc, onSnapshot, query } from 'firebase/firestore';
import { auth, db } from '../../firebase/Firebase';
import ModuleInfoCard from './Cards/ModuleInfoCard';
import Loader from '../../component/sidebar/Loader';

const ModuleBody = () => {

  const [load, setLoad] = useState(true)


    const [module, setModule] = useState();

    useEffect(() => {
      const todosref = collection(db, "TuffTrainer");
      const q = query(todosref);
      const unsub = onSnapshot(q, (querySnapshot) => {
        let Blog = [];
        querySnapshot.forEach((doc) => {
          Blog.push({
            id: doc.id,
            ...doc.data(),
          });
          setModule(Blog);
          setLoad(false)
        });
      });
  
      return () => unsub();
    }, []);

    const [unlockid, setUnlockid] = useState()
  
const uid = auth.currentUser.uid

    useEffect(() => {
      getDoc(doc(db, "usersLesson", uid , 'MemberShip' , 'TuffTrainerCertification')).then((docSnap) => {
        if (docSnap.exists()) {
          setUnlockid(docSnap.data());
        } else {
          console.log("No Document Found");
        }
      });
    },[uid])


    

if(load === true) {
return(
  <Loader />
)
} else {
  return (
    <div className="ModuleBody">
     <div className="ModuleBodyTop">
         <div className="ModuleBodyTopPanel">
 
         </div>
         <div className="ModuleBodyMidBody">
 <div className="ModuleBodyMidBodyLeft">
 <h1 className='ModuleBodyMidBodyh1'>
 Modules Content
 </h1>
 {module?.slice(0,2).map((item, key) => (
     <>
     
 <ModuleLessonCard unlock={true} item={item} key={key}/>
     </>
 ))}
 {module?.slice(2).map((item, key) => (
     <>
     
 <ModuleLessonCard   unlock={unlockid?.unlock} item={item} key={key}/>
     </>
 ))}
 
 
 </div>
 <div className="ModuleBodyMidBodyRight">
 <ModuleInfoCard />
 </div>
         </div>
     </div>
    </div>
   )
}

}

export default ModuleBody