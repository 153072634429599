import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderTwo from "../../component/layout/header-2";
import PageHeader from "../../component/layout/pageheader";

import Footer from "../../component/layout/footer";
import { doc, getDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";
import { db, logInWithEmailAndPassword } from "../../firebase/Firebase";

const title = "Login";
const btnText = "Login";

const LoginPage = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    // userLogin(email, password)
    //   .then((result) => {
    //     const user = result.user;
    //     if (user) {
    //       toast.success("Successfully Login!");
    //       navigate(from, { replace: true });
    //     }
    //   })
    //   .catch((error) => toast.error(error));

    getDoc(doc(db, "users", email.toLowerCase())).then((docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().loginPermission === true) {
          try {
            logInWithEmailAndPassword(email, password).then(() => {
              navigate("/loginVerification", { replace: true });
            });
          } catch (error) {}
        } else {
          // navigate("/");

          toast.success("Not verified", {
            position: "top-center",
          });
          //   toast.
          //   toast.error("Account is not verified by Admin", {
          //     position: "top-right",
          //   });
        }
      } else {
        toast.success("No Account", {
          position: "top-center",
        });
        //   toast.

        // toast.error("No Account Registered", {
        //   position: "top-right",
        // });
        // navigate("/");
      }
    });
  };

  return (
    <Fragment>
      <HeaderTwo />
      <PageHeader title={"Login Page"} curPage={"Login"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form onSubmit={handleLogin} className="account-form">
              <div className="form-group">
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  name="emai;"
                  placeholder="Email *"
                />
              </div>
              <div className="form-group">
                <input
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  name="password"
                  placeholder="Password *"
                />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                  <div className="checkgroup">
                    <input type="checkbox" name="remember" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                  <Link to="/Forget">Forget Password?</Link>
                </div>
              </div>
              <div className="form-group text-center">
                <button type="submit" className="d-block lab-btn">
                  <span>{btnText}</span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Don’t Have any Account? <Link to="/SignUp">Sign Up</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default LoginPage;
