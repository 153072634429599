import React , {Fragment}from 'react'
import HeaderTwoAuth from '../../component/layout/header-2-auth'
import Compare from '../../component/section/Compare'

import { useEffect } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import { auth, db } from '../../firebase/Firebase'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'

const Dashboard1 = () => {

const [user, setUser] = useState()
const uid =  auth.currentUser.uid

  useEffect(() => {
    const unsub = onSnapshot(
      doc(db, "usersLesson", uid, "MemberShip", "TuffTrainerCertification"),
      (doc) => {
        setUser(doc.data());
      }
    );

    return () => unsub();
  }, [uid]);


  if(user?.Active === true) {

    
    return (
      
      <Fragment>
    <HeaderTwoAuth />
    <Compare />
   </Fragment>

)
} else {
  <Navigate to={'/Home'} replace/>
}
}

export default Dashboard1