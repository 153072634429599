import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import HeaderTwo from "../../component/layout/header-2";
import PageHeaderTwo from "../../component/layout/pageheader-2";
import Respond from "../../component/sidebar/respond";
import CourseSideDetail from "../../component/sidebar/course-detail";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";
import Accordition from "./Accordition";

const CourseDetailStandard = () => {
  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "TuffTrainer");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, []);

  return (
    <Fragment>
      <HeaderTwo />
      <PageHeaderTwo />
      <div
        style={{ paddingTop: "110px", paddingBottom: "110px" }}
        className="course-single-section padding-tb section-bg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h3>Course Overview</h3>
                      <p>
                        This course will help you out in building up your vision
                        towards fitness business management and get properly
                        trained how to manage clients even retain them.
                        <br />
                        If you get a basic program then you will only get access
                        to chapter oriented forums where you can ask questions
                        related to chapter .
                        <br />
                        You will be getting a 1yr access to it.
                      </p>
                      <h4>What You'll Learn in This Course:</h4>
                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>Creating a
                          healthy client base and transformation will be easy.
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i> Financial
                          development will be maximized with a progressive rate
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Wisdom related
                          nutrition science and supplementation will amplify
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Research based
                          detailed information on lifestyles health issue will
                          help you deal with client issues.{" "}
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>With knowledge
                          comes power of progressive understanding towards
                          clients goal and purposely act on it.
                        </li>
                      </ul>
                      <p>
                        Courses will offer you with all these facilities which
                        help the change the entire financial scenario of trainer
                        and coach's who have a large pool of clients or want to
                        have a bigger pool of clients.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="course-video">
                  <div className="course-video-title">
                    <h4>Course Content</h4>
                  </div>
                  <div className="course-video-content">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        {module?.map((item, key) => (
                          <>
                            <div className="accordion-header" id="accordion01">
                              <button
                                className="d-flex flex-wrap justify-content-between"
                                data-bs-toggle="collapse"
                                data-bs-target={`#videolist${key}`}
                                aria-expanded="true"
                                aria-controls={`#videolist${key}`}
                              >
                                <span>
                                  {item.Module}. {item.NAME}
                                </span>{" "}
                                <span>{item.chapters} chapters</span>{" "}
                              </button>
                            </div>
                            <Accordition item={item} id={key} />
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <Respond />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-part">
                <CourseSideDetail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CourseDetailStandard;
