import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import {toast} from 'react-hot-toast'

const Verification = () => {

  const { user,  } = useContext(AuthContext);
    const navigate = useNavigate()
    useEffect(()=> {
       if(user){
navigate('/Home')

toast.success('Login Successfully.', {
  position: "top-center"
})
       } else if (!user) {
        navigate('/Login')
       }
    })
  return (
 <>
 </>
  )
}

export default Verification