/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./VideoLecture.css";

import QuestionSlider from "../Chapters/QuestionSlider";
import { Fragment } from "react";
import { useState } from "react";

import Accordition3 from "../Membership/Accordition3";
import { AiOutlineSend } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { FileUploader } from "react-drag-drop-files";
import Modal from "react-modal";
import { toast } from "react-hot-toast";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  onSnapshot,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, storage } from "../../firebase/Firebase";
import moment from "moment";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";

const VideoBody = ({ item, chapter }) => {
  const { user } = useContext(AuthContext);

  const [getUser, setGetUser] = useState("");

  useEffect(() => {
    getDoc(doc(db, "usersLesson", user?.uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      } else {
        console.log("No Document Found");
      }
    });
  }, [user?.uid]);

  const [data1, setData1] = useState(chapter);

  const [lang, setLang] = useState(data1);
  const [lang1, setLang1] = useState("Hindi");

  const onChangeLesson = (item, key) => {
    setData1(item);
    setLang(item);
    setMark(false);
    setExist(false);
    setData([]);
  };

  const [data, setData] = useState();
  const [exist, setExist] = useState(false);

  const languageChange = (data1) => {
    setLang1(data1);
  };

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");

  const fileTypes = ["JPEG", "PNG"];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
    // document.getElementsByClassName('dropdown').style.zIndex = "1"
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  }

  const addDiscussion = async () => {
    if (file) {
      const uploadImg = async () => {
        const imgRef = ref(
          storage,
          `forums/${new Date().getTime()} - ${file.name}`
        );
        try {
          const snap = await uploadBytes(imgRef, file);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await addDoc(
            collection(
              db,
              "TuffTrainer",
              item?.id,
              item?.id,
              chapter?.id,
              "Forums"
            ),
            {
              Image: url,
              name: name,
              description,
              tags,
              user: getUser?.name,
              email: getUser?.email,
              verified: "pending",
              createdAt: moment().format(),
              replies: 0,
              lastreply: "0",
              userId: user.uid,
            }
          );
          addDoc(collection(db, "usersLesson", getUser.uid, "Notification"), {
            Notification: `Your Forum's Approval is Pending`,
            markasRead: false,
          });
          addDoc(collection(db, "QuestionBankNotify"), {
            item: item?.id,
            chapter: chapter?.name,
            forum: `New Forum Uploaded in Chapter ${chapter.name} `,
            data:serverTimestamp(),
          });
          updateDoc(doc(db, "usersLesson", getUser.uid), {
            NotificationNumber: increment(1),
          })
            .then(() => {
              toast("Forum will posted after approval", {
                duration: 2000,
                position: "top-center",
                // icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
              toast("Forum Added", {
                duration: 2000,
                position: "top-center",
                icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
              closeModal();
            })
            .then(() => {
              setName("");
              setDescription("");
              setFile();
              setTags("");
            });
        } catch (err) {
          console.log(err);
        }
      };
      uploadImg();
    } else {
      await addDoc(
        collection(
          db,
          "TuffTrainer",
          item?.id,
          item?.id,
          chapter?.id,
          "Forums"
        ),
        {
          name: name,
          description,
          tags,
          user: getUser?.name,
          email: getUser?.email,
          verified: "pending",
          createdAt: moment().format(),
          replies: 0,
          lastreply: "0",
          userId: user.uid,
        }
      );
      addDoc(collection(db, "usersLesson", getUser.uid, "Notification"), {
        Notification: `Your Forum's Approval is Pending`,
        markasRead: false,
      });
      addDoc(collection(db, "QuestionBankNotify"), {
        item: item?.id,
        chapter: chapter?.name,
        forum: `New Forum Uploaded in Chapter ${chapter.name} `,
        data:serverTimestamp(),
      });
      updateDoc(doc(db, "usersLesson", getUser.uid), {
        NotificationNumber: increment(1),
      })
        .then(() => {
          toast("Forum will posted after approval", {
            duration: 2000,
            position: "top-center",
            // icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          toast("Question Added", {
            duration: 2000,
            position: "top-center",
            icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          closeModal();
        })
        .then(() => {
          setName("");
          setDescription("");
          setFile();
          setTags("");
        });
    }
  };

  const onClickMarkAsComplete = async () => {
    const washingtonRef = doc(
      db,
      "usersLesson",
      user?.uid,
      "MemberShip",
      "TuffTrainerCertification",
      item.id,
      data1.chapter
    );
    const washingtonRef1 = doc(db, "usersLesson", user?.uid);

    // Set the "capital" field of the city 'DC'
    await setDoc(washingtonRef, {
      completed: true,
    }).then(() => {
      updateDoc(washingtonRef1, {
        Module1: increment(25),
      }).then(() => {
        onSnapshot(doc(db, "usersLesson", user?.uid), (doc) => {
          setGetUser(doc.data());
        });
      });

      toast.success("Marked as Completed");
    });
  };

  const [mark, setMark] = useState();

  useEffect(() => {
    onSnapshot(
      doc(
        db,
        "usersLesson",
        user?.uid,
        "MemberShip",
        "TuffTrainerCertification",
        item.id,
        data1.chapter
      ),
      (doc) => {
        setMark(doc.data()?.completed);
        // console.log(doc.data());
      }
    );
  }, [data1.chapter, item.id, user?.uid]);

  return (
    <Fragment>
      <div className="course-view-section padding-tb section-bg section-bgggg">
        {mark === true ? (
          <>
            <a href="#" class="float">
              <i class="fa fa-plus my-float">Completed</i>
            </a>
          </>
        ) : (
          <>
            <a onClick={() => onClickMarkAsComplete()} class="float">
              <i class="fa fa-plus my-float">Mark as Complete</i>
            </a>
          </>
        )}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="course-view">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-12">
                    <div className="video-part mb-4 mb-lg-0">
                      <div className="vp-title mb-4 mt-4">
                        <h3 className="headingchaptername">
                          {data1?.name} ({lang1})
                        </h3>
                      </div>
                      <div className="vp-video mb-4">
                        {lang1 === "Hindi" ? (
                          <>
                            {lang?.Hurl === "" ? (
                              <>
                                <h1 className="videonotavailableh1">
                                  Hindi Video Uploaded Soon...
                                </h1>
                              </>
                            ) : (
                              <>
                                <iframe
                                  src={lang?.Hurl}
                                  style={{
                                    border: 0,
                                    height: "500px",
                                    width: "100%",
                                    maxWidth: "100%",
                                    borderRadius: "15px",
                                  }}
                                  allowFullScreen="true"
                                  allow="encrypted-media"
                                  title="TuffTrainer"
                                ></iframe>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {lang?.Eurl === "" ? (
                              <>
                                <h1 className="videonotavailableh1">
                                  English Video Uploaded Soon...
                                </h1>
                              </>
                            ) : (
                              <>
                                <iframe
                                  src={lang?.Eurl}
                                  style={{
                                    border: 0,
                                    height: "500px",
                                    width: "100%",
                                    maxWidth: "100%",
                                    borderRadius: "15px",
                                  }}
                                  allowFullScreen="true"
                                  allow="encrypted-media"
                                  title="TuffTrainer"
                                ></iframe>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-8 col-12">
                    <div className="video-list-area">
                      <div className="video-head"></div>
                      <div className="video-body">
                        <div className="course-select-area border-radius-12">
                          <div className="csa-title mb-4 dropdownheading">
                            <h5>Chapter List</h5>
                            <label class="dropdown">
                              <div class="dd-button">Language</div>

                              <input
                                type="checkbox"
                                class="dd-input"
                                id="test"
                              />

                              <ul class="dd-menu">
                                <li
                                  onClick={() => {
                                    setLang1("English");
                                    languageChange("English");
                                  }}
                                >
                                  English
                                </li>
                                <li
                                  onClick={() => {
                                    setLang1("Hindi");
                                    languageChange("Hindi");
                                  }}
                                >
                                  Hindi
                                </li>
                              </ul>
                            </label>
                          </div>
                          <div className="csa-body heifhtsetcsabody">
                            <ul className="lab-ul">
                              <li>
                                <div id="accordion">
                                  <>
                                    <div className="card active bg-ash mb-1">
                                      <div
                                        className="card-header bg-transparent border-bottom-0"
                                        id={`acc-list-1`}
                                      >
                                        <h5 className="mb-0">
                                          <button
                                            className="w-100 border-0 bg-transparent outline-none text-left"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#acc-1`}
                                            aria-expanded="true"
                                            aria-controls={`#acc1`}
                                          >
                                            {item.Module}: {item?.NAME}
                                            <span className="d-block font-weight-normal">
                                              Videos: {item?.chapters}
                                            </span>
                                            <div className="icon">
                                              <i className="icofont-thin-down"></i>
                                            </div>
                                          </button>
                                        </h5>
                                      </div>
                                      <div
                                        id={`acc-1`}
                                        className="collapse show"
                                        aria-labelledby={`acc-list-1`}
                                        data-bs-parent="#accordion"
                                      >
                                        <div className="card-body py-0">
                                          <Accordition3
                                            onChangeLesson={onChangeLesson}
                                            lang={lang1}
                                            item={item}
                                            id={1}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
          <h3 className="headingchaptername">
                     Question Bank ({data1?.name})
                        </h3>
          </div>
          <div className="ChapterBodyBottom">
            <div className="ChapterBodyBottomBackground">
              <div className="ChapterBodyBottomBackgroundLeft"></div>
              <div className="ChapterBodyBottomBackgroundRight"></div>
              <div className="QUestionsCardsEction">
                <div className="AddQuestionCard">
                  <div className="QuestionCardTop">
                    <div className="QuestionCardTopCircle"></div>
                    <div className="QuestionCardTopText">
                      <h1>Ask any Question?</h1>
                    </div>
                  </div>
                  <div className="QuestionCardMiddle">
                    <div className="QuestionCardMiddleButton">
                      <button onClick={openModal}>New Question</button>
                    </div>
                    <ul className="QuestionCardMiddleUL">
                      <li className="QuestionCardMiddleLI">
                        <span>
                          DROP ALL YOUR LECTURE RELATED QUESTIONS HERE
                        </span>
                      </li>
                      <li className="QuestionCardMiddleLI">
                        <span>
                          ONLY DROP YOUR LECTURE SPECIFIC QUESTIONS HERE
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                
                <QuestionSlider
                  exist={exist}
                  setExist={setExist}
                  data={data}
                  setData={setData}
                  sliderload={true}
                  chapterData={data1}
                  item={item}
                  name="QuestionCardSection3"
                />
              </div>
            </div>
            <div className="ChapterBodyBottomBody">
              <QuestionSlider
                exist={exist}
                setExist={setExist}
                data={data}
                setData={setData}
                sliderload={true}
                chapterData={data1}
                item={item}
                name="QuestionCardSection2"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="header-modal-s">
          <div className="search-input">
            <input
              placeholder="Discussion Title"
              type="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              required
            />
          </div>
        </div>

        <hr />
        {/* textarea-modal */}
        <div>
          <div className="modal-text-area-data-1">
            <div className="text-area-modal-s">
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="text-modal-area1"
                placeholder="Type your discussion content here"
                name=""
                id=""
                cols="30"
                rows="10"
                required
              ></textarea>
            </div>
            <div className="upload-options">
              {/* <input type="file" name="" id="" /> */}
              <FileUploader
                classes="drop_area"
                multiple={false}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
            </div>
          </div>
          <hr />
          <div className="section-1-text">
            <textarea
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              placeholder="Type one or more tag, comma separated "
              className="text-area-modal-2"
              name=""
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <hr />
          <div className="check-box-post-btn">
            <div className="check-box-post-btn1">
              <button
                disabled={!name || !description}
                onClick={() => addDiscussion()}
                className="post-btn-dis"
              >
                Send <AiOutlineSend />
              </button>
              <button onClick={closeModal} className="post-btn-dis">
                Cancel <MdCancel />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default VideoBody;
