import { Fragment } from "react";


import HeaderTwo from "../../component/layout/header-2";
import Footer from "../../component/layout/footer";
import PageHeader from "../../component/layout/pageheader";
import Student from "../../component/section/student";
import AchievementTwo from "../../component/section/achievement-2";




const subTitle = "About Our Edukon";
const title = "Good Qualification Services And Better Skills";
const desc = "Distinctively provide acces mutfuncto users whereas transparent proceses somes ncentivize eficient functionalities rather than extensible archtectur communicate leveraged services and cross-platform.";

const year = "30+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: require('../../assets/images/about/icon/01.jpg'),
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: require('../../assets/images/about/icon/02.jpg'),
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: require('../../assets/images/about/icon/03.jpg'),
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <HeaderTwo />
            <PageHeader title={'About Tuff Trainer'} curPage={'About'} />
            <div style={{paddingTop: '110px' , paddingBottom: '110px'}} className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
                        <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src={require('../../assets/images/about/01.jpg')} alt="about" />
                                </div>
                                <div className="abs-thumb">
                                    <img src={require('../../assets/images/about/02.jpg')} alt="about" />
                                </div>
                                <div className="about-left-content">
                                    <h3>{year}</h3>
                                    <p>{expareance}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about-right">
                                <div className="section-header">
                                    <span className="subtitle">{subTitle}</span>
                                    <h2 className="title">{title}</h2>
                                    <p>{desc}</p>
                                </div>
                                <div className="section-wrapper">
                                    <ul className="lab-ul">
                                        {aboutList.map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-left">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="sr-right">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Student />
           
          
            <AchievementTwo />
      
            <Footer />
        </Fragment>
    );
}

export default AboutPage;
 