/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "../Module.css";
import { auth, db } from "../../../firebase/Firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import Progress_bar from "../../../component/sidebar/PercentLine";
import { MdPlayLesson } from "react-icons/md";
import { GrCertificate } from "react-icons/gr";

const ModuleInfoCard = () => {
  const [getUser, setGetUser] = useState();
  const uid = auth.currentUser.uid;

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "usersLesson", uid), (doc) => {
      setGetUser(doc.data());
    });

    return () => unsub();
  }, [uid]);

  const [Total, setTotal] = useState();

  useEffect(() => {
    getDoc(doc(db, "TuffTrainer", "Module1")).then((docSnap) => {
      if (docSnap.exists()) {
        setTotal(docSnap.data());
      }
    });
  }, [uid]);

  return (
    <div className="ModuleInfoCard">
      <div className="ModuleInfoCardTop"></div>
      <div className="ModalInfoCardMiddle">
        <Progress_bar
          bgcolor="#0072BB"
          progress={(parseInt(getUser?.Module1) / Total?.TotalModule) * 100}
          height={5}
        />
        <div>
          <h1 className="percentage-course-com">
            {((parseInt(getUser?.Module1) / Total?.TotalModule) * 100).toFixed(2)}% Complete
          </h1>
        </div>
        <h1 className="ModalInfoCardMiddleh12">Course Details</h1>
        <div className="ModuleInfoFottoer">
          <div className="ModuleInfoFottoerSection1">
            <h5>{Total?.TotalMod}</h5>
            <h5 className="ModuleInfoFottoerSection1h1">
              <MdPlayLesson size={25} /> {Total?.TotalLesson} Lessons
            </h5>
            <h5 className="ModuleInfoFottoerSection1h1">
              <GrCertificate size={25} /> Certification
            </h5>
          </div>
          <div className="ModuleInfoFottoerSection2">
          <button class="button-7" role="button">Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleInfoCard;
