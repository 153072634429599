import React, { useEffect, useState } from "react";
import "./Vlog.css";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/sidebar/Loader";

const VlogBody = () => {
  const[load,setLoad] = useState(true)
  const [vlogs,setVlogs] = useState()

  useEffect(() => {
    setLoad(true)
    const todosref = collection(db, "Vlogs");
    const q = query(todosref , orderBy('num' , 'asc'));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setVlogs(Blog);
        setLoad(false)
      });
    });

    return () => unsub();
  }, []);


  const navigate = useNavigate()

if(load === true){
return(
  <Loader />
)
} else {
  return (
    <div className="VlogBody">
        <h1 className="vlogs-heading">Vlogs</h1>
        <div className="vlogs-card-s1">


  
  <div class="ag-courses_box">
    {vlogs?.map((item, key) => (
<>
<div onClick={() => navigate('/VlogPlayer' , {state: { vlogs: vlogs , item: item}})} className="vlog-card ag-courses_item">
          <div className="vlog-thum">
            <img
              src={item?.thumbnail}
              alt=""
            />
          </div>
          <div className="vlog-details">
            <img src={require("../../assets/images/logo/Tuff.png")} alt="" />
            <div className="content-vlog-details">
              <h1>{item?.name}</h1>

              <h2> VLOG {item?.num}</h2>
            </div>
          </div>
        </div>
</>
    ))}



  

  </div>
  </div>
</div>
  
  );
}

 
};

export default VlogBody;
