
import { Link } from "react-router-dom";

const subTitle = "Tuff Trainer";
const title = "Key Features";


const categoryList = [
    {
        imgUrl: require('../../assets/images/category/icon/07.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'UNLIMITED COURSE UPDATES.', 
    },
    {
        imgUrl: require('../../assets/images/category/icon/11.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'SEPARATE ENGLISH / HINDI LECTURES.',
        desc: '27 Course',
    },
 
    {
        imgUrl: require('../../assets/images/category/icon/12.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'FORUM SECTION FOR QUESTIONS AND ANSWERS.',
        desc: '87 Course',
    },
    {
        imgUrl: require('../../assets/images/category/icon/10.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'SIMPLE AND EASY TO GRASP.',
        desc: '',
    },
    {
        imgUrl: require('../../assets/images/category/icon/08.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'LIVE QUESTIONS AND ANSWERS AND NEW TOPIC DISCUSSIONS SESSIONS .',
        desc: '',
    },
 

    {
        imgUrl: require('../../assets/images/category/icon/09.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'BE A LIFETIME PART ONE OF THE BEST COACHING COMMUNITY.',
        desc: '',
    },
    {
        imgUrl: require('../../assets/images/category/icon/13.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'COVERED EVERY ASPECTS OF NUTRITION AND TRAINING IN DETAILS.',
        desc: '15 Course',
    },
    {
        imgUrl: require('../../assets/images/category/icon/14.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'PRACTICAL ASPECTS OF FITNESS BUSINESS COVERED.',
        desc: '58 Course',
    },
]

const CategoryTwo = () => {
    return (
        <div style={{paddingTop: '110px' , paddingBottom: '110px'}} className="category-section padding-tb section-bg style-2">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="/course"><h6>{val.title}</h6></Link>
                                            {/* <span>{val.desc}</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CategoryTwo;