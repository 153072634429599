/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";
// import Rating from "../sidebar/rating";


const subTitle = "";
const title = "Students Community Feedback";


const studentList = [
    {
        imgUrl: require('../../assets/images/feedback/student/03.jpg'),
        imgAlt: 'student rajibraj91 rajibraj',
        name: 'Inu sharma',
        degi: 'Tuff Trainer Student',
        desc: 'I Inu Sharma had done many certifications prior to joining TUFF Trainer course. With the ample knowledge and techniques I had learned from this course I had been able to guide my Clients with very effective diet plans and work out patterns. I had achieved many transformations with in time frame. Best part of Team TUFF trainer is every query I had asked was not only answered and explained but it was ensured and checked that my concept is clear.Thanks a ton to mentor Atinder Sir and team TUFF trainer for launching such an amazing platform. Highly recommend for those who want to learn and grow with the best.',
    },
    {
        imgUrl: require('../../assets/images/feedback/student/03.jpg'),
        imgAlt: 'student rajibraj91 rajibraj',
        name: 'Piyush tariyal',
        degi: 'Tuff Trainer Student',
        desc: 'Hello teamThis is Piyush Tariyal. I have taken your course Tuff Trainer certification program.The skills i get from this course are very valuable in my life.The course was well organised ,clear and east to follow. the instructors was very knowledgeable with excellent presentation skills. He did great job in covering the course material.Thankyou very much i got exactly what i wanted in this course.',
    },
]


const Student = () => {
    return (
        <div style={{paddingTop: '110px' , paddingBottom: '110px'}} className="student-feedbak-section padding-tb shape-img">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        <div className="col">
                            <div className="sf-left">
                                <div className="sfl-thumb">
                                    <img src={require('../../assets/images/feedback/01.jpg')}  alt="student feedback" />
                                    <a  className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            {studentList.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="stu-feed-top">
                                            <div className="sft-left">
                                                <div className="sftl-thumb">
                                                    <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                                </div>
                                                <div className="sftl-content">
                                                    <Link><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                </div>
                                            </div>
                                            <div className="sft-right">
                                                {/* <Rating /> */}
                                            </div>
                                        </div>
                                        <div className="stu-feed-bottom">
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Student;