/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.scss";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../firebase/Firebase";
import { useState } from "react"; 

const CourseTemp = ({ children }) => {
  const showMenu = (headerToggle, navbarId) => {
    const toggleBtn = document.getElementById(headerToggle),
      nav = document.getElementById(navbarId);

    // Validate that variables exist
    if (headerToggle && navbarId) {
      toggleBtn.addEventListener("click", () => {
        // We add the show-menu class to the div tag with the nav__menu class
        nav.classList.toggle("show-menu");
        // change icon
        toggleBtn.classList.toggle("bx-x");
      });
    }
  };


  const [openNav , setOpenNav] = useState(false)


  const showMenuBox  = () => {
    setOpenNav(true)
    document.getElementById("");
  }
  const closeMenuBox  = () => {
    setOpenNav(false)
  }


  /*==================== LINK ACTIVE ====================*/
  const linkColor = document.querySelectorAll(".nav__link");

  function colorLink() {
    linkColor.forEach((l) => l.classList.remove("active"));
    this.classList.add("active");
  }

  linkColor.forEach((l) => l.addEventListener("click", colorLink));

  const { pathname } = useLocation();
  const { userLogout } = useContext(AuthContext);


  
  const handleLogout = () => {
    userLogout()
      .then((result) => {
        // toast.success("Successfully Logout!");
        toast.success('Successfully Logout!', {
            position: "top-center"
          })
    

      })
      .catch((error) => console.error(error));
  };


  const [data,setData] =useState()
  const [check , setCheck] = useState()

const uid = auth.currentUser.uid
  useEffect(() => {
    getDoc(doc(db, "usersLesson", uid)).then((docSnap) => {
      if (docSnap.exists()) {
    
        var str = docSnap.data().name;
        var matches = str.match(/\b(\w)/g);
        setData(matches.join(""));
        setCheck(docSnap.data())
      } else {
        console.log("No Document Found");
      }
    });
  }, [uid]);


    
  const handleLogout1 = () => {
    userLogout()
      .then((result) => {
        // toast.success("Successfully Logout!");
        toast.error(`You don't have Login Permission`, {
            position: "top-center"
          })
    

      })
      .catch((error) => console.error(error));
  };


  useEffect(() => {
if(check?.loginPermission === false) {
  handleLogout1()
}
  },[check])





  return (
    <div className="CoursesBody">
      <header class="header">
        <div class="header__container">
          <p
            // src={require("../../assets/images/author/01.jpg")}
            alt=""
            class="header__img"
          >
            {data}
          </p>

          <a id="anchora12345" class="header__logo">
            <img src={require("../../assets/images/logo/01.png")} alt="" />
          </a>

          <div class="header__search">
            <input type="search" placeholder="Search" class="header__input" />
            <i class="bx bx-search header__icon"></i>
          </div>

          {openNav === true ? (
  <>
          <div
            onClick={() => closeMenuBox()}
            class="header__toggle"
          >
            <i
              style={{ fontSize: "40px" }}
              // onClick={() => showMenu("header-toggle", "navbar")}
              class="bx bx-x"
              id="header-toggle"
            ></i>
          </div>
  </>
) : (
  <>
          <div
            onClick={() => showMenuBox()}
            class="header__toggle"
          >
            <i
              style={{ fontSize: "40px" }}
              // onClick={() => showMenu("header-toggle", "navbar")}
              class="bx bx-menu"
              id="header-toggle"
            ></i>
          </div>
  
  </>
)}
        </div>
      </header>

      <div class={openNav === true ? 'nav show-menu' : 'nav'} id="navbar">
        <nav class="nav__container">
          <div>
            <a id="anchora12345" href="#" class="nav__link nav__logo">
              <i class="nav__icon"></i>
              <span class="nav__logo-name"></span>
            </a>

            <div class="nav__list">
              <div class="nav__items">
                <h3 id="h3333333333" class="nav__subtitle">
                  Profile
                </h3>

                <Link
                  to={"/Courses"}
                  id="anchora12345"
                  class={
                    pathname === "/Courses"
                      ? "nav__link activenav"
                      : "nav__link"
                  }
                >
                  <i class="bx bx-home nav__icon"></i>
                  <span class="nav__name">Home</span>
                </Link>
                <Link
                  id="anchora12345"
                  to={"/Modules"}
                  class={
                    pathname === "/Modules"
                      ? "nav__link activenav"
                      : "nav__link"
                  }
                >
                  <i class="bx bx-book-content  nav__icon"></i>
                  <span class="nav__name">Modules</span>
                </Link>
                <Link
                  to={"/Forums"}
                  id="anchora12345"
                  href="#"
                  class="nav__link"
                >
                  <i class="bx bx-message-rounded nav__icon"></i>
                  <span class="nav__name">Forums</span>
                </Link>

                <Link
                  to={"/Profile"}
                  id="anchora12345"
                  href="#"
                  class="nav__link"
                >
                  <i class="bx bx-user nav__icon"></i>
                  <span class="nav__name">Profile</span>
                </Link>
                <Link to={'/Vlogs'} id="anchora12345"  class="nav__link">
                  <i class="bx bxs-camera-movie  nav__icon"></i>
                  <span class="nav__name">Vlogs</span>
                </Link>
              </div>

              <div class="nav__items">
                <h3 id="h3333333333" class="nav__subtitle">
                  Coming Soon
                </h3>

            

             
                <a onClick={() => toast.error('Coming Soon')}  id="anchora12345"  class="nav__link">
                  <i class="bx bxs-user-account  nav__icon"></i>
                  <span class="nav__name">Case Study</span>
                </a>
                <a onClick={() => toast.error('Coming Soon')}  id="anchora12345" class="nav__link">
                  <i class="bx bxs-webcam  nav__icon"></i>
                  <span class="nav__name">Live Stream</span>
                </a>
              </div>
            </div>
          </div>

          <a id="anchora12345" onClick={() => handleLogout()} class="nav__link nav__logout">
            <i class="bx bx-log-out nav__icon"></i>
            <span class="nav__name">Log Out</span>
          </a>
        </nav>
      </div>
      {children}
    </div>
  );
};

export default CourseTemp;
