/* eslint-disable jsx-a11y/iframe-has-title */



const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3428.001972439011!2d76.8105623761193!3d30.774524083610856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ff30c45f69961%3A0x73b61388de19ab8f!2sDelta%20Infox!5e0!3m2!1sen!2sin!4v1695149350202!5m2!1sen!2sin" width="600" height="450" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap; 