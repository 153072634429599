import "./App.css";
import { RouterProvider } from "react-router-dom";
import "swiper/css";

import { router } from "./Routes/Route/Route";
import { Toaster } from "react-hot-toast";


function App() {
  return (
    <>
       <RouterProvider router={router}></RouterProvider>
    <Toaster/>
    </>
  );
}

export default App;
