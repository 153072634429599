import {  Fragment } from "react";
import BannerFive from "../../component/section/banner-5";
import HeaderTwoAuth from "../../component/layout/header-2-auth";

const Dashboard = () => {
  return (
    <Fragment>
  <HeaderTwoAuth />
  <BannerFive />
</Fragment>
  )
}

export default Dashboard