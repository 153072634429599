/* eslint-disable react/jsx-no-target-blank */

// import Rating from "../sidebar/rating";

const title = "Tuff Trainer Certification";
const desc = "";
const author = "Atinderjeet Singh";
const reviewCount = "";



const categoryList = [
    {
        link: '#',
        text: 'Tuff Trainer Certification',
        className: 'course-cate',
    },
    {
        link: '#',
        text: 'Regular Membership',
        className: 'course-offer', 
    },
]


const PageHeaderTwo = () => {
    return (
        <div className="pageheader-section style-2">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                    <div className="col-lg-7 col-12">
                        <div className="pageheader-thumb">
                            <img src={require('../../assets/images/pageheader/02.jpg')} alt="rajibraj91" className="w-100" />
                            <a href={'https://firebasestorage.googleapis.com/v0/b/tuff-trainer.appspot.com/o/IntroVideo%2Fintro%20tuff.mp4?alt=media&token=4aad4618-1878-413a-af96-05692af7f3df'} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="pageheader-content">
                            <div className="course-category">
                                {categoryList.map((val, i) => (
                                    <a href={val.link} className={val.className} key={i}>{val.text}</a>
                                ))}
                            </div>
                            <h2 className="phs-title">{title}</h2>
                            <p className="phs-desc">{desc}</p>
                            <div className="phs-thumb">
                                <img src={require('../../assets/images/course/author/40.jpg')} alt="rajibraj91" />
                                <span>{author}</span>
                                <div className="course-reiew">
                                    {/* <Rating /> */}
                                    <span className="ratting-count">{reviewCount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PageHeaderTwo;