import { createBrowserRouter } from "react-router-dom";

import Main from "../../layout/Main";
import PrivateRoute from "../PrivateRoute/PrivateRouting";
import Home from "../../Pages/Home/Home";
import CourseDetailStandard from "../../Pages/CourseDetail/CourseDetailStandard";
import CourseDetailPremium from "../../Pages/CourseDetail/CourseDetailPremium";
import AboutPage from "../../Pages/About/About";
import ContactPage from "../../Pages/Contact/Contact";
import LoginPage from "../../Pages/Authentication/Login";
import SignupPage from "../../Pages/Authentication/SignUp";
import Dashboard from "../../AuthPages/Home/Home";
import Verification from "../../Pages/Authentication/Verification";
import Courses from "../../AuthPages/Courses/Courses";
import Standard from "../../AuthPages/Membership/Standard";
import Premium from "../../AuthPages/Membership/Premium";
import Dashboard1 from "../../CoursePages/Dashboard/Dashboard";
import Modules from "../../AuthPages/Modules/Modules";
import Chapters from "../../AuthPages/Chapters/Chapters";
import VideoLecture from "../../AuthPages/VideoLecture/VideoLecture";
import Forum from "../../AuthPages/Forum/Forum";
import ReplyForum from "../../AuthPages/Forum/ReplyForum/ReplyForum";
import Profile from "../../AuthPages/Profile/Profile";
import QuestionReply from "../../AuthPages/Forum/QuestionReply/QuestionReply";
import Vlog from "../../AuthPages/Vlogs/Vlog";
import VlogsPlayer from "../../AuthPages/Vlogs/VlogsPlayer/VlogsPlayer";
import Forget from "../../Pages/Authentication/Forget";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/loginVerification",
        element: <Verification></Verification>,
      },
      {
        path: "/CourseDetailStandard",
        element: <CourseDetailStandard></CourseDetailStandard>,
      },
      {
        path: "/CourseDetailPremium",
        element: <CourseDetailPremium></CourseDetailPremium>,
      },
      {
        path: "/About",
        element: <AboutPage></AboutPage>,
      },
      {
        path: "/ContactUs",
        element: <ContactPage></ContactPage>,
      },
      {
        path: "/Login",
        element: <LoginPage></LoginPage>,
      },
      {
        path: "/Forget",
        element: <Forget></Forget>,
      },
      {
        path: "/SignUp",
        element: <SignupPage></SignupPage>,
      },

      {
        path: "/Home",
        element: (
          <PrivateRoute>
            <Dashboard></Dashboard>
          </PrivateRoute>
        ),
      },
      {
        path: "/Courses",
        element: (
          <PrivateRoute>
            <Courses></Courses>
          </PrivateRoute>
        ),
      },
      {
        path: "/StandardMembership",
        element: (
          <PrivateRoute>
            <Standard></Standard>
          </PrivateRoute>
        ),
      },
      {
        path: "/PremiumMembership",
        element: (
          <PrivateRoute>
            <Premium></Premium>
          </PrivateRoute>
        ),
      },
      {
        path: "/Dashboard1",
        element: (
          <PrivateRoute>
            <Dashboard1></Dashboard1>
          </PrivateRoute>
        ),
      },

      {
        path: "/Modules",
        element: (
          <PrivateRoute>
            <Modules></Modules>
          </PrivateRoute>
        ),
      },
      {
        path: "/Chapters",
        element: (
          <PrivateRoute>
            <Chapters></Chapters>
          </PrivateRoute>
        ),
      },
      {
        path: "/Lectures",
        element: (
          <PrivateRoute>
            <VideoLecture></VideoLecture>
          </PrivateRoute>
        ),
      },

      {
        path: "/Forums",
        element: (
          <PrivateRoute>
            <Forum></Forum>
          </PrivateRoute>
        ),
      },

      {
        path: "/ReplyData/:id",
        element: (
          <PrivateRoute>
            <ReplyForum></ReplyForum>
          </PrivateRoute>
        ),
      },
      {
        path: "/Profile",
        element: (
          <PrivateRoute>
            <Profile></Profile>
          </PrivateRoute>
        ),
      },
      {
        path: "/QuestionReply",
        element: (
          <PrivateRoute>
            <QuestionReply></QuestionReply>
          </PrivateRoute>
        ),
      },

      {
        path: "/Vlogs",
        element: (
          <PrivateRoute>
            <Vlog></Vlog>
          </PrivateRoute>
        ),
      },
      {
        path: "/VlogPlayer",
        element: (
          <PrivateRoute>
            <VlogsPlayer></VlogsPlayer>
          </PrivateRoute>
        ),
      },
    ],
  },
]);
