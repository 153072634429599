


const subTitle = "Online education";
const title = <h2 className="title"><span className="d-lg-block">Changing</span> Your Professional <span className="d-lg-block">& Financial Life</span></h2>;
const desc = "Free online courses from the world’s Leading experts. join 18+ million Learners today.";


const catagoryList = [
    {
        name: 'Figma',
        link: '#',
    }, 
    {
        name: 'Adobe XD',
        link: '#',
    },
    {
        name: 'illustration',
        link: '#',
    },
    {
        name: 'Photoshop',
        link: '#',
    },
]


const BannerTwo = () => {
    return (
        <section className="banner-section style-2">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-xxl-5 col-xl-6 col-lg-10 me-auto">
                            <div className="banner-content"> 
                                <h6 className="subtitle text-uppercase fw-medium">{subTitle}</h6>
                                {title}
                                {/* <p className="desc">{desc}</p> */}
                             
                           
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6">
                            <div className="thumb-part">
                                <div className="banner-thumb text-center">
                                    <img src={require('../../assets/images/banner/02.png')}  alt="img" />
                                </div>
                                <div className="abs-thumb d-none d-xxl-block">
                                    <img src={require('../../assets/images/banner/03.png')} alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default BannerTwo;