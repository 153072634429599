
import { Link } from "react-router-dom";

const price = "20000";
const excenge = "Limited time offer";
const btnText = "Enrolled Now";


const csdcList = [
    {
        iconName: 'icofont-ui-alarm',
        leftText: 'Course level',
        rightText: 'Advanced',
    },
    {
        iconName: 'icofont-book-alt',
        leftText: 'Modules',
        rightText: '4',
    },
  
    {
        iconName: 'icofont-video-alt',
        leftText: 'Lessions',
        rightText: '34x',
    },
  
  
    {
        iconName: 'icofont-certificate',
        leftText: 'Certificate',
        rightText: 'Yes',
    },
    {
        iconName: 'icofont-globe',
        leftText: 'Language',
        rightText: 'English/Hindi',
    },
]



const CourseSideDetail = () => { 
    return (
        <div className="course-side-detail">
            <div className="csd-title">
                <div className="csdt-left">
                    <h4 className="mb-0"><sup>₹</sup>{price}</h4>
                </div>
                <div className="csdt-right">
                    <p className="mb-0"><i className="icofont-clock-time"></i>{excenge}</p>
                </div>
            </div>
            <div className="csd-content">
                <div className="csdc-lists">
                    <ul className="lab-ul">
                        {csdcList.map((val, i) => (
                            <li key={i}>
                                <div className="csdc-left"><i className={val.iconName}></i>{val.leftText}</div>
                                <div className="csdc-right">{val.rightText}</div>
                            </li>
                        ))}
                    </ul>
                </div>
          
             
                <div className="course-enroll">
                    <Link to="/signup" className="lab-btn"><span>{btnText}</span></Link>
                </div>
            </div>
        </div>
    );
}
 
export default CourseSideDetail;