import React, { Fragment, useState, useEffect } from "react";
import HeaderTwoAuth from "../../component/layout/header-2-auth";
import PageHeader from "../../component/layout/pageheader";
import {

  collection,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase/Firebase";
import Accordition2 from "./Accordition2";
import Compare from "../../component/section/Compare";
import Modal from "react-modal";
import logo from "../../assets/images/logo/png.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Standard = () => {
  const [icon, setIcon] = useState(false);

  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "TuffTrainer");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, []);

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "scroll";
  }

  const uid = auth.currentUser.uid;

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const [user, setUser] = useState();

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "usersLesson", uid), (doc) => {
      setUser(doc.data());
    });

    return () => unsub();
  }, [uid]);

  const displayRazorpay = () => {
    try {
      const res = loadScript("https://checkout.razorpay.com/v1/checkout.js");

      if (!res) {
        alert("You are offline... Failed to load Razorpay SDK");
      }

      const options = {
        key: "rzp_live_8u1YZKSi5THsSt",
        currency: "INR",
        amount: 20000 * 100,
        name: "Delta Infox LLP",
        description: "Thanks for purchasing",
        image: logo,

        theme: {
          color: "#0072bb",
        },

        handler: function (response) {
          closeModal();
          // onOpenModal();

          setDoc(
            doc(
              db,
              "usersLesson",
              uid,
              "MemberShip",
              "TuffTrainerCertification"
            ),
            {
              MemberShip:
                "Personal Trainer AND Advanced Nutrition Certification",
              Subscription: "Regular",
              Active: true,
              createdAt: serverTimestamp(),
              Expires: "Never",
              PaymentMethod: "N/A",
              unlock: true,
            }
          );

          //   navigate('/OrderConfirmed')
        },
        prefill: {
          name: user?.name,
          email: user?.email,
          contact: user?.phone,
        },

        // if(response.razorpay_payment_id)
      };
    

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert(error);
    }
  };

  const displayRazorpay1 = () => {
    try {
      const res = loadScript("https://checkout.razorpay.com/v1/checkout.js");

      if (!res) {
        alert("You are offline... Failed to load Razorpay SDK");
      }

      const options = {
        key: "rzp_live_8u1YZKSi5THsSt",
        currency: "INR",
        amount: 10000 * 100,
        name: "Delta Infox LLP",
        description: "Thanks for purchasing",
        image: logo,

        theme: {
          color: "#0072bb",
        },

        handler: function (response) {
          closeModal();
          // onOpenModal();

          setDoc(
            doc(
              db,
              "usersLesson",
              uid,
              "MemberShip",
              "TuffTrainerCertification"
            ),
            {
              MemberShip:
                "Personal Trainer AND Advanced Nutrition Certification",
              Subscription: "Regular",
              Active: true,
              createdAt: serverTimestamp(),
              Expires: "Never",
              PaymentMethod: "N/A",
              unlock: false,
            }
          );

          //   navigate('/OrderConfirmed')
        },
        prefill: {
          name: user?.name,
          email: user?.email,
          contact: user?.phone,
        },

        // if(response.razorpay_payment_id)
      };
    

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert(error);
    }
  };


  return (
    <Fragment>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="Modalllling">
          <h1 className="Modallllingh1">Payment Type</h1>
          <div className="Boxxxxx">
            <div className="Booxxxxxx1">
              <p className="boxxxxxxxxx1p">OTP</p>
              <p className="boxxxxxxxxx1p1">(One Time Payment)</p>
              <button
                onClick={() => displayRazorpay()}
                type="button"
                class="btn btn-primary"
              >
                ₹20,000
              </button>
            </div>
            <div className="Booxxxxxx2">
              <p className="boxxxxxxxxx1p">EMI's</p>
              <p className="boxxxxxxxxx1p1">(Ease Monthly Installement)</p>
              <button onClick={() => displayRazorpay1()} type="button" class="btn btn-primary">
                ₹10,000 <small>/2 EMI's</small>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <HeaderTwoAuth />
      <PageHeader title={"Regular Membership"} curPage={"Courses"} />
      <div
        style={{ paddingTop: "110px", paddingBottom: "110px" }}
        className="course-view-section padding-tb section-bg"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="course-view">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-12">
                    <div className="video-part mb-4 mb-lg-0">
                      <div className="vp-title mb-4">
                        <h3>Tuff Trainer Certification</h3>
                      </div>
                      <div className="vp-video mb-4">
                        <video controls>
                          <source
                            src="https://firebasestorage.googleapis.com/v0/b/tuff-trainer.appspot.com/o/IntroVideo%2Fintro%20tuff.mp4?alt=media&token=4aad4618-1878-413a-af96-05692af7f3df"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div className={`content-wrapper ${icon ? "open" : ""}`}>
                        <div
                          className="content-icon d-lg-none"
                          onClick={() => setIcon(!icon)}
                        >
                          <i className="icofont-caret-down"></i>
                        </div>
                        <div className="vp-content mb-5">
                          <h4>Introduction</h4>
                          <p>
                          This course will help you out in building up your vision towards fitness business management and get properly trained how to manage clients even retain them. 
<br />
If you get a basic program then you will only get access to chapter oriented forums where you can ask questions related to chapter . 
<br />
You will be getting a 1yr access to it.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-8 col-12">
                    <div className="video-list-area">
                      <div className="video-head"></div>
                      <div className="video-body">
                        <div className="course-select-area border-radius-12">
                          <div className="csa-title mb-4">
                            <h5>Course Details</h5>
                          </div>
                          <div className="csa-body">
                            <ul className="lab-ul">
                              <li>
                                <div id="accordion">
                                  {module?.map((item, key) => (
                                    <>
                                      <div className="card bg-ash mb-1">
                                        <div
                                          className="card-header bg-transparent border-bottom-0"
                                          id={`acc-list-${key}`}
                                        >
                                          <h5 className="mb-0">
                                            <button
                                              className="w-100 border-0 bg-transparent outline-none text-left"
                                              data-bs-toggle="collapse"
                                              data-bs-target={`#acc-${key}`}
                                              aria-expanded="true"
                                              aria-controls={`#acc-${key}`}
                                            >
                                              {item.Module}: {item?.NAME}
                                              <span className="d-block font-weight-normal">
                                                Videos: {item?.chapters}
                                              </span>
                                              <div className="icon">
                                                <i className="icofont-thin-down"></i>
                                              </div>
                                            </button>
                                          </h5>
                                        </div>
                                        <div
                                          id={`acc-${key}`}
                                          className="collapse"
                                          aria-labelledby={`acc-list-${key}`}
                                          data-bs-parent="#accordion"
                                        >
                                          <div className="card-body py-0">
                                            <Accordition2
                                              item={item}
                                              id={key}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              </li>
                            </ul>
                            <div class="col-md-12 col-sm-6 mb-3">
                              <button
                                onClick={openModal}
                                class="btn btn-success w-100"
                              >
                                Enroll Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Compare />
    </Fragment>
  );
};

export default Standard;
