import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React , {useState , useEffect} from 'react'
import { db } from '../../firebase/Firebase';

const Accordition = ({item , id}) => {


    const [module, setModule] = useState();



    useEffect(() => {
      const todosref = collection(db, "TuffTrainer", item?.id, item?.id);
      const q = query(todosref, orderBy("num", "asc"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let Blog = [];
        querySnapshot.forEach((doc) => {
          Blog.push({
            id: doc.id,
            ...doc.data(),
          });
          setModule(Blog);
        });
      });
  
      return () => unsub();
    }, [item?.id]);

  return (
    <div id={`videolist${id}`} className="accordion-collapse collapse" aria-labelledby="accordion02" data-bs-parent="#accordionExample">
                                                    <ul className="lab-ul video-item-list">
                                                        {module?.map((item, key) => (
  <li className=" d-flex flex-wrap justify-content-between">
  <div className="video-item-title">{key + 1} {item.name}</div>
  
</li>
                                                        ))}
                                                      
                                                      
                                                    </ul>
                                                </div>
  )
}

export default Accordition