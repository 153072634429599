/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Fragment } from "react";

import { Link } from "react-router-dom";
import HeaderTwoAuth from "../../component/layout/header-2-auth";
import PageHeader from "../../component/layout/pageheader";
import Compare from "../../component/section/Compare";
import { useState } from "react";
import { auth, db } from "../../firebase/Firebase";
import { useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import './styles.scss';
import CourseBodyMain from "./CourseBodyMain";
import CourseTemp from "./CourseTemp";
import Loader from "../../component/sidebar/Loader";

const courseList = [
  {
    imgUrl: require("../../assets/images/course/01.jpg"),
    imgAlt: "course rajibraj91 rajibraj",
    price: "₹20k",
    cate: "Regular Membership",
    reviewCount: "",
    title: "Tuff Trainer Certification",
    totalLeson: "34x Lesson",
    schdule: "Online Class",
    authorImgUrl: require("../../assets/images/course/author/40.jpg"),
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Atinderjeet Singh",
    btnText: "Read More",
    link: "/StandardMembership",
  },
  {
    imgUrl: require("../../assets/images/course/02.jpg"),
    imgAlt: "course rajibraj91 rajibraj",
    price: "₹30k",
    cate: "VIP Membership",
    reviewCount: "",
    title: "Tuff Trainer Certification",
    totalLeson: "34x Lesson",
    schdule: "Online Class",
    authorImgUrl: require("../../assets/images/course/author/40.jpg"),
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Atinderjeet Singh",
    btnText: "Read More",
    link: "/PremiumMembership",
  },
];

const Courses = () => {
  const [user, setUser] = useState();
  const uid = auth.currentUser.uid;

  const [load, setLoad] = useState(true)

  useEffect(() => {
    setLoad(true)
    const unsub = onSnapshot(
      doc(db, "usersLesson", uid, "MemberShip", "TuffTrainerCertification"),
      (doc) => {
        setUser(doc.data());
 setLoad(false)
    }
    );

    return () => unsub();
  }, [uid]);




if(load === true) {

 return(
    <Loader />
 )
} else {
    if (user?.Active) {
        return (
          <Fragment>
         <CourseTemp>
            <CourseBodyMain />
         </CourseTemp>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <HeaderTwoAuth />
            <PageHeader title={"Courses"} curPage={"Courses"} />
            <div
              style={{ paddingTop: "110px", paddingBottom: "110px" }}
              className="course-section padding-tb section-bg"
            >
              <div className="container">
                <div className="section-wrapper">
                  <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
                    {courseList.map((val, i) => (
                      <div className="col" key={i}>
                        <div className="course-item">
                          <div className="course-inner">
                            <div className="course-thumb">
                              <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                            </div>
                            <div className="course-content">
                              <div className="course-price">{val.price}</div>
                              <div className="course-category">
                                <div className="course-cate">
                                  <a href="#">{val.cate}</a>
                                </div>
                                <div className="course-reiew">
                                  <span className="ratting-count">
                                    {" "}
                                    {val.reviewCount}
                                  </span>
                                </div>
                              </div>
                              <Link to={val.link}>
                                <h4>{val.title}</h4>
                              </Link>
                              <div className="course-details">
                                <div className="couse-count">
                                  <i className="icofont-video-alt"></i>{" "}
                                  {val.totalLeson}
                                </div>
                                <div className="couse-topic">
                                  <i className="icofont-signal"></i> {val.schdule}
                                </div>
                              </div>
                              <div className="course-footer">
                                <div className="course-author">
                                  <img
                                    src={`${val.authorImgUrl}`}
                                    alt={`${val.authorImgAlt}`}
                                  />
                                  <Link to="/team-single" className="ca-name">
                                    {val.authorName}
                                  </Link>
                                </div>
                                <div className="course-btn">
                                  <Link
                                    to="/course-single"
                                    className="lab-btn-text"
                                  >
                                    {val.btnText}{" "}
                                    <i className="icofont-external-link"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <Compare />
          </Fragment>
        );
      }
}
};

export default Courses;
