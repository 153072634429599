
import { Link } from "react-router-dom";


const subTitle = "Welcome To";
const title = <h2 className="title"><span></span> Tuff Trainer</h2>;
const desc = "";
const btnText = "View Course";

 

const BannerFive = () => {








    

    return (
        <section className="banner-section style-5">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row">
                        <div className="offset-md-6 col-xl-6">
                            <div className="banner-content"> 
                          
                                <p className="desc">{desc}</p>
                                <h4 className="subtitle">{subTitle}</h4>
                                {title}
                                <Link to={'/Courses'} className="lab-btn"><span>{btnText}</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default BannerFive;