import React , {useState} from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";


const Compare2 = () => {

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };


  return (
    <>
  <Tabs>
 
   
 
      <div className="container mt-5 mb-5 pb-5">
        <section class="text-center">
          <h4 class="mb-4">
            <strong>Pricing</strong>
          </h4>
          <div class="btn-group mb-4" style={{display: 'flex' , flexDirection: 'column' , justifyContent: 'center' , alignItems: 'center' , rowGap: '10px'}} role="group" aria-label="Basic example">
         
            <TabList style={{listStyle: 'none' , display: 'flex' , gap: '5px'}}>
      <Tab >
      <button onClick={() => toggleTab(1)} data-toggle="tooltip" data-placement="top" title="One time Payment" type="button" class={toggleState === 1 ? "btn btn-dark" : "btn btn-outline-dark"}>OTP</button>
      </Tab>
      <Tab>
      <button onClick={() => toggleTab(2)} type="button" class={toggleState === 2 ? "btn btn-dark" : "btn btn-outline-dark"}>EMI's</button>
      </Tab>
    </TabList>
          </div>
        
        </section>
        <TabPanel>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-3">
      
            <div class="card border border-dark">
              <div class="mx-2 card-body">
                <h5 class="card-title my-2 ">Regular Membership</h5>
                <p class="text-muted">
                  All the essentials for starting a business
                </p>
                <p class="h2 fw-bold">
                  ₹20,000
                  <small
                    class="text-muted"
                    style={{ fontSize: "18px" }}
                  ></small>
                </p>
                <Link
                  to={"/CourseDetailStandard"}
                  style={{ color: "white" }}
                  class="btn btn-dark d-block mb-2 mt-3 text-capitalize"
                >
                  Enroll Now
                </Link>
              </div>
              <div class="card-footer">
                <p class="text-uppercase fw-bold" style={{ fontSize: "12px" }}>
                  What's included
                </p>
                <ol class="list-unstyled mb-0 px-4">
                  <p class="my-3 fw-bold text-muted text-center"></p>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Video Lectures</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>All Modules Unlocked</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Forums Section</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Case Study</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Vlogs</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Live Streams</small>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card border border-dark">
              <div class="mx-2 card-body">
                <h5 class="card-title my-2 ">VIP Membership</h5>
                <p class="text-muted">
                  All the essentials for starting a business
                </p>
                <p class="h2 fw-bold">
                  ₹30,000
                  <small
                    class="text-muted"
                    style={{ fontSize: "18px" }}
                  ></small>
                </p>
                <Link
                  to={"/CourseDetailPremium"}
                  style={{ color: "white" }}
                  class="btn btn-dark d-block mb-2 mt-3 text-capitalize"
                >
                  Enroll Now
                </Link>
              </div>
              <div class="card-footer">
                <p class="text-uppercase fw-bold" style={{ fontSize: "12px" }}>
                  What's included
                </p>
                <ol class="list-unstyled mb-0 px-4">
                  <p class="my-3 fw-bold text-muted text-center"></p>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Video Lectures</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>All Modules Unlocked</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Forums Section</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Case Study</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Vlogs</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Live Streams</small>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>
    </TabPanel>
    <TabPanel>
    <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-3">
      
            <div class="card border border-dark">
              <div class="mx-2 card-body">
                <h5 class="card-title my-2 ">Regular Membership</h5>
                <p class="text-muted">
                  All the essentials for starting a business
                </p>
                <p class="h2 fw-bold">
                  ₹10,000
                  <small
                    class="text-muted"
                    style={{ fontSize: "18px" }}
                  >/2 EMI's</small>
                </p>
                <Link
                  to={"/CourseDetailStandard"}
                  style={{ color: "white" }}
                  class="btn btn-dark d-block mb-2 mt-3 text-capitalize"
                >
                  Enroll Now
                </Link>
              </div>
              <div class="card-footer">
                <p class="text-uppercase fw-bold" style={{ fontSize: "12px" }}>
                 1st EMI
                </p>
                <ol class="list-unstyled mb-0 px-4">
                  <p class="my-3 fw-bold text-muted text-center"></p>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Video Lectures</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Half Modules Unlocked</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Forums Section</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Case Study</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Vlogs</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Live Streams</small>
                  </li>
                </ol>
              </div>
              <div class="card-footer">
                <p class="text-uppercase fw-bold" style={{ fontSize: "12px" }}>
                 2nd EMI
                </p>
                <ol class="list-unstyled mb-0 px-4">
                  <p class="my-3 fw-bold text-muted text-center"></p>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Video Lectures</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>All Modules Unlocked</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Forums Section</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Case Study</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Vlogs</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Live Streams</small>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card border border-dark">
              <div class="mx-2 card-body">
                <h5 class="card-title my-2 ">VIP Membership</h5>
                <p class="text-muted">
                  All the essentials for starting a business
                </p>
                <p class="h2 fw-bold">
                  ₹15,000
                  <small
                    class="text-muted"
                    style={{ fontSize: "18px" }}
                  >/2 EMI's</small>
                </p>
                <Link
                  to={"/CourseDetailPremium"}
                  style={{ color: "white" }}
                  class="btn btn-dark d-block mb-2 mt-3 text-capitalize"
                >
                  Enroll Now
                </Link>
              </div>
              <div class="card-footer">
                <p class="text-uppercase fw-bold" style={{ fontSize: "12px" }}>
                 1st EMI
                </p>
                <ol class="list-unstyled mb-0 px-4">
                  <p class="my-3 fw-bold text-muted text-center"></p>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Video Lectures</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Half Modules Unlocked</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Forums Section</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Case Study</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Vlogs</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <RxCross1 size={18} color="red" />
                    </i>
                    <small>Live Streams</small>
                  </li>
                </ol>
              </div>
              <div class="card-footer">
                <p class="text-uppercase fw-bold" style={{ fontSize: "12px" }}>
                 2nd EMI
                </p>
                <ol class="list-unstyled mb-0 px-4">
                  <p class="my-3 fw-bold text-muted text-center"></p>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Video Lectures</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>All Modules Unlocked</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Forums Section</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Case Study</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Vlogs</small>
                  </li>
                  <li class="mb-3">
                    <i class="fas fa-check text-success me-3">
                      <AiOutlineCheck size={18} color="green" />
                    </i>
                    <small>Live Streams</small>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>
    </TabPanel>
        
      </div>
      </Tabs>
    </>
  );
};

export default Compare2;
